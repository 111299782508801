<template>
  <div class="pt-5 pl-10 pr-10">
    <h2 class="mb-5">Nhận xét hàng tháng</h2>
    <Autocomplete
      :style="{ width: '180px' }"
      @setFilter="setFilter"
      :styleBorder="''"
      :keyFilter="'none'"
      :labelSelect="'class'"
      :selectedInit="listClassSelect"
      :listItem="listClassCode"
    />

    <div ref="listComment">
      <v-progress-linear indeterminate v-if="loading" color="primary"></v-progress-linear>
      <div v-else>
        <CommentItem
          v-for="comment in dataComments"
          @setLog="setLog"
          :key="comment.commentID"
          :comment="comment"
          :isGuardianFeedback="true"
        />
      </div>
    </div>
    <h4 v-if="dataComments.length < 1" class="nodata">No data in here</h4>
    <DigCommentDetailStudent
      @getListComment="getListComment"
      :comment="commentSelect"
      :dialog="dialog"
      @setLog="setLog"
    />
  </div>
</template>

<script>
import { monthly_comment } from '@/api/monthly.js'
import { class_managerment } from '@/api/class-management'
import CommentItem from '@/components/MonthCommentStudent/CommentItem.vue'
import DigCommentDetailStudent from '@/components/MonthCommentStudent/DigCommentDetailStudent.vue'
import Autocomplete from '@/components/Utils/Autocomplete.vue'
import dayjs from 'dayjs'
import SortByOrder from '@/mixins/sortClassByOrder.js'
export default {
  components: {
    CommentItem,
    DigCommentDetailStudent,
    Autocomplete,
  },
  mixins: [SortByOrder],
  setup() {
    return {
      dayjs: dayjs,
    }
  },
  data() {
    return {
      dialog: false,
      userInfor: JSON.parse(localStorage.getItem('currentUser')).userInfo,
      commentSelect: {},
      dataComments: [],
      listClass: [],
      listClassCode: [],
      loading: false,
      listClassSelect: [],
    }
  },
  created() {
    this.getListClassStuding()
    this.getListComment()
    //FOR REDIRECTING NOTI
    // setTimeout(() => {
    //   const month = '08/2023'
    //   const targetElement = Array.from(document.querySelectorAll('.comment-header')).find(el =>
    //     el.innerText.includes(month)
    //   )
    //   const y = targetElement.getBoundingClientRect().top + window.scrollY - 100

    //   window.scrollTo({ top: y, behavior: 'smooth' })
    //   // targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
    // }, 4000)
  },
  onMounted() {
    const payload = this.$store.getters.getNotiPayload
    if (!this.listClassSelect.includes(payload.classIDs)) {
      this.listClassSelect.push(payload.classIDs)
      this.scrollToTarget(payload.month)
    }
  },
  computed: {
    notiPayload() {
      return this.$store.getters.getNotiPayload
    },
  },
  methods: {
    setFilter(objectFilterChange) {
      this.listClassSelect = objectFilterChange.filter
    },
    //serve for monthcomt noti
    scrollToTarget(month) {
      const targetElement = Array.from(document.querySelectorAll('.comment-header')).find(el =>
        el.innerText.includes(month)
      )
      const y = targetElement.getBoundingClientRect().top + window.scrollY - 100

      window.scrollTo({ top: y, behavior: 'smooth' })
      // targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
    },
    async getListComment() {
      if (this.userInfor.studentID && this.userInfor.studentID !== 0) {
        let listCLassToString = this.listClass
          .filter(item => this.listClassSelect.includes(item.classCode))
          .map(item => item.classID)
          .join(',')
        if (this.listClassSelect.length < 1) {
          listCLassToString = this.listClass.map(item => item.classID).join(',')
        }

        this.loading = true
        await monthly_comment
          .getCommentByStudent(this.userInfor.userID, listCLassToString)
          .then(res => {
            this.dataComments = res.reverse()
            this.loading = false
          })
          .catch(() => {
            this.dataComments = []
            this.loading = false
          })
      }
    },
    async getListClassStuding() {
      await class_managerment
        .getClassRe(this.userInfor.userID)
        .then(res => {
          const targetResult = this.getLatestInprogress(res)
          this.listClass = targetResult
          this.listClassSelect.push(targetResult[0].classCode)
          this.listClassCode = targetResult.map(el => el.classCode)
        })

        .catch(err => {
          this.listClass = []
          this.listClassSelect = []
          this.listClassCode = []
          console.log(err)
        })
    },
    setLog(inf) {
      if (inf) {
        this.dialog = inf.dialog
        this.commentSelect = inf.comment
      }
    },
  },
  watch: {
    listClassSelect: function () {
      this.getListComment()
    },
    notiPayload: {
      handler(newVal) {
        // Handle the change in notiPayload
        if (Object.keys(newVal).length > 0) this.getAnnouncementDetail()
      },
      immediate: true, // Trigger the handler immediately when the component is created
    },
  },
}
</script>

<style lang="scss" scoped>
.select-class {
  width: 200px;
}
.nodata {
  text-align: center;
}
</style>
